import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@opoint/infomedia-storybook'
import { Trans, useTranslation } from 'react-i18next'
import { useWatch } from 'react-hook-form'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useProfilesList } from '../../../api/profiles/profiles.ts'
import SelectField from '../select/Select.tsx'

export const ProfileParentFormField = () => {
  const { t } = useTranslation()
  const params = useParams()
  const profileId = params?.profileId

  const { data: profiles = [] } = useProfilesList()

  const profileFolder = useWatch({ name: 'folder' })

  const parentOptions = useMemo(() => {
    const filteredProfiles = profiles.filter(({ folder, category }) =>
      profileFolder ? folder === Number(profileFolder) : category === 0,
    )

    const profilesWithoutActiveProfile = profileId
      ? filteredProfiles.filter(({ id }) => id?.toString() !== profileId)
      : filteredProfiles

    const sortedProfiles = profilesWithoutActiveProfile.sort((a, b) => a.name.localeCompare(b.name))

    return [
      { id: 0, name: t('No parent') },
      ...sortedProfiles.map((profile) => ({ id: profile.id ?? null, name: profile.name })),
    ]
  }, [profileId, profiles, profileFolder, t])

  return (
    <FormField
      name="parent"
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            <Trans>Parent</Trans>
          </FormLabel>
          <FormControl>
            {/*  @ts-expect-error - The input prop is typed for redux-form, not for react-hook-form.  */}
            <SelectField defaultValue={0} input={field} options={parentOptions} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
