import { Combobox, ComboboxContent, ComboboxItem, ComboboxTrigger } from '@opoint/infomedia-storybook'
import { useTranslation } from 'react-i18next'
import { SelectHTMLAttributes, useMemo, useState } from 'react'
import { WrappedFieldProps } from 'redux-form'

type SelectFieldProps = {
  options: { id: number | null; name: string }[]
  placeholder?: string
} & SelectHTMLAttributes<HTMLSelectElement> &
  WrappedFieldProps

const SelectField = ({ options, placeholder, ...props }: SelectFieldProps) => {
  const { t } = useTranslation()
  const {
    input: { value, onChange },
  } = props
  const [query, setQuery] = useState('')

  const filteredOptions = useMemo(
    () => options.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase() || '')),
    [options, query],
  )

  return (
    <Combobox onChange={onChange} value={value.toString()}>
      <ComboboxTrigger
        displayValue={(value) => options.find((option) => option.id === Number(value))?.name || ''}
        placeholder={placeholder ?? t('Select an option')}
        onQueryChange={setQuery}
      />
      <ComboboxContent>
        {filteredOptions.map((option) => (
          <ComboboxItem value={option.id ? option.id.toString() : ''} key={option.id}>
            {option.name}
          </ComboboxItem>
        ))}
      </ComboboxContent>
    </Combobox>
  )
}

export default SelectField
